import { ApolloError } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { SimplifiedCategory } from 'modules/example_decks'
import SalPainting from 'publicImages/Sal-Painting-2x.png'

import { TemplateBrowserMain } from './TemplateBrowserMain'
import { TemplateBrowserSidebar } from './TemplateBrowserSidebar'

const TemplateBrowserError = ({
  error,
  onClose,
}: {
  error?: ApolloError
  onClose: () => void
}) => {
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <Flex direction="column" justify="center" align="center" w="100%">
          <Image
            src={SalPainting.src}
            width="300px"
            alt="Sal, the Gamma mascot, perched on a rocket enjoying some screentime"
            mb={6}
          />
          <Heading size="sm" mb={2} textAlign="center">
            Oops, this is embarrassing.
          </Heading>
          <Text fontSize="md" color="gray.400" textAlign="center">
            We had trouble loading the templates. Please try again later.
            <br />
            {error?.message && error.message}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="solid" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

const TemplateBrowserSkeleton = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <ModalHeader>Templates</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex direction="row">
          <Box w={48} h="100%">
            <Skeleton w={'100%'} h="40px" mb={2} />
            <Skeleton w={'100%'} h="40px" mb={2} />
            <Skeleton w={'100%'} h="40px" mb={2} />
          </Box>
          <Flex flex={1} w="100%" pl={6} direction="column">
            <Skeleton w="50%" h="48px" mb={4} />
            <Box w="100%">
              <Grid
                templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
                gap={6}
              >
                <GridItem
                  bg="linen.50"
                  borderRadius="5px"
                  height="200px"
                  as={Skeleton}
                />
                <GridItem
                  bg="linen.50"
                  borderRadius="5px"
                  height="200px"
                  as={Skeleton}
                />
                <GridItem
                  bg="linen.50"
                  borderRadius="5px"
                  height="200px"
                  as={Skeleton}
                />
              </Grid>
            </Box>
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="solid" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </>
  )
}
type TemplateBrowserProps = {
  categories?: SimplifiedCategory[]
  onClose: () => void
  onPreviewClick: () => void
  currentCategory: any
  setCurrentCategoryId: any
  forceChannelId?: string | null
  isLoading: boolean
  error?: ApolloError
}
export const TemplateBrowser = ({
  categories,
  onClose,
  onPreviewClick,
  currentCategory,
  setCurrentCategoryId,
  forceChannelId,
  isLoading,
  error,
}: TemplateBrowserProps) => {
  const [
    createDocAndNavigate,
    { data: createdDocData, loading: createdDocLoading },
  ] = useCreateDocAndNavigate()
  if (isLoading) {
    return <TemplateBrowserSkeleton onClose={onClose} />
  }
  if (error) {
    return <TemplateBrowserError error={error} onClose={onClose} />
  }
  return (
    <>
      <ModalHeader>Templates</ModalHeader>
      <ModalCloseButton />
      <ModalBody overflow="hidden">
        <Flex direction="row" h="100%">
          {categories && categories?.length > 0 && (
            <TemplateBrowserSidebar
              currentCategoryId={currentCategory.id}
              setCurrentCategoryId={setCurrentCategoryId}
              categories={categories}
            />
          )}
          {currentCategory?.decks?.length > 0 && (
            <TemplateBrowserMain
              category={currentCategory}
              onPreviewClick={onPreviewClick}
              forceChannelId={forceChannelId}
              onClose={onClose}
            />
          )}
        </Flex>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button
          size="md"
          variant="ghost"
          disabled={createdDocLoading}
          leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
          onClick={() => {
            createDocAndNavigate({
              channelId: forceChannelId,
              source: 'template_browser',
            })
          }}
        >
          New blank {DOC_DISPLAY_NAME}
        </Button>
        <Button variant="solid" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </>
  )
}
