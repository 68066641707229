// This component contains ONLY the code relevant to render a preview
// of each theme, consisting of a card with a background, a title, body
// text, and a link, as well as a theme font loader.
import { Box, Flex, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { memo } from 'react'

import { isThemeDark, Theme } from 'modules/theming'
import { ThemeFontLoader } from 'modules/theming/components/FontLoader'
import { CARD_CONTENT_CLASS } from 'modules/tiptap_editor/extensions/Card'
import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from 'modules/tiptap_editor/styles/backgroundStyles'

import { getThemeStylesheet } from '../styles'
import { HEADING_CLASS } from '../styles/heading'
import { getThemeCSSVars } from '../styles/variables'
import { getThemeBase } from '../themeBases'

type ThemePreviewThumbnailProps = {
  theme: Theme
  variant?: 'outline' | 'ghost'
}

export const ThemePreviewThumbnail = memo(
  ({ theme, variant = 'outline' }: ThemePreviewThumbnailProps) => {
    const { contentStyles } = theme.config
    const background = getDocOrThemeBackground(theme)
    const isDark = isThemeDark(theme)
    const cssVars = getThemeCSSVars(theme)
    const themeStyles = getThemeStylesheet(theme, false)
    const isGhost = variant === 'ghost'
    const themeBase = getThemeBase(theme)

    return (
      <Box css={cssVars} sx={themeStyles} w="100%">
        <Flex
          borderRadiusTop={isGhost ? 'none' : 'md'}
          boxShadow="sm"
          _focus={{
            boxShadow: 'outline',
          }}
          p={4}
          minH="9em"
          maxH="9em"
          transitionProperty="common"
          transitionDuration="normal"
          {...getBackgroundProps(background, isDark)}
          backgroundAttachment="initial"
          direction="row"
          align="center"
        >
          <ThemeFontLoader theme={theme} />
          {/* The container */}
          <Box
            sx={{
              ...themeBase.cardSx,
              ...contentStyles,
            }}
            p={4}
            borderRadius="base"
            className={`${CARD_CONTENT_CLASS} ${isDark ? 'is-dark' : ''}`}
            color={isDark ? 'white' : 'gray.900'}
            flex={1}
            pointerEvents="none" // Prevent hovers
          >
            <Text
              className="block-title"
              mt={'0 !important'}
              mb={'var(--chakra-space-2) !important'}
            >
              <Text
                as="span"
                className={cx('title', HEADING_CLASS)}
                fontSize="1.8em !important"
                paddingBottom="0 !important"
              >
                Title
              </Text>
            </Text>
            <Text
              fontSize="sm"
              noOfLines={3}
              className="block block-paragraph"
              my="0 !important"
            >
              Body &{' '}
              <Text as="a" className="link">
                link
              </Text>
            </Text>
          </Box>
        </Flex>
      </Box>
    )
  }
)

ThemePreviewThumbnail.displayName = 'ThemePreviewThumbnail'
