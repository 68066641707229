import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useCreateDocMutation } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { DocCreatedSource } from 'modules/segment'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import {
  emptyDoc,
  emptyDocWithQuickstart,
  SCHEMA_VERSION,
} from 'modules/tiptap_editor'
import {
  htmlToYDocSnapshot,
  jsonToYDocSnapshot,
} from 'modules/tiptap_editor/utils/transform'
import { useUserContext } from 'modules/user'

type CreateDocAndNavigateParams = {
  source: DocCreatedSource
  html?: string
  channelId?: string | null
  title?: string
  emptyDocType?: 'quickstart' | 'empty'
  queryParams?: {
    tour?: boolean
  } | null
}

export const useCreateDocAndNavigate = () => {
  const { user, currentWorkspace } = useUserContext()
  const cardLayoutsEnabled = useFeatureFlag('cardLayoutsEnabled')

  const [createDoc, { data, loading }] = useCreateDocMutation()

  const { push } = useRouter()

  const toast = useToast()

  const createDocAndNavigate = useCallback(
    (params: CreateDocAndNavigateParams) => {
      const html = params?.html
      const source = params.source
      const channelId = params?.channelId
      const emptyDocType = params?.emptyDocType || 'empty'
      if (!currentWorkspace?.id) {
        console.error('[createDocAndNavigate] No currentWorkspace found')
        return
      }
      const docFlags = {
        cardLayoutsEnabled,
      }
      const document =
        emptyDocType === 'empty'
          ? emptyDoc(user, { docFlags })
          : emptyDocWithQuickstart(user, { docFlags })
      const encodedDocument = html
        ? htmlToYDocSnapshot(html)
        : jsonToYDocSnapshot(document)

      console.debug('[createDocAndNavigate] Creating doc', {
        document,
        encodedDocument,
      })

      return createDoc({
        variables: {
          workspaceId: currentWorkspace?.id,
          title: params?.title || '',
          initialContent: {
            ydoc: encodedDocument,
            schemaVersion: SCHEMA_VERSION,
            editors: [user?.id!],
          },
          ...(channelId ? { channels: [{ channelId }] } : {}),
        },
      })
        .then(async ({ data: createData }) => {
          trackDocCreatedEvent({
            doc_id: createData?.createDoc.id,
            source,
            channel: channelId ? channelId : null,
          })

          console.debug(
            '[Header] Doc created. Navigating to Editor',
            createData?.createDoc.id
          )
          push(
            `/docs/${createData?.createDoc.id}${
              params.queryParams?.tour ? '?tour=true' : ''
            }`
          )
        })
        .catch((error) => {
          console.error('[createDocAndNavigate]', error)
          toast({
            title: 'Error creating document',
            description: error.message,
            position: 'top',
            status: 'error',
            isClosable: true,
            duration: null,
          })
        })
    },
    [cardLayoutsEnabled, createDoc, currentWorkspace?.id, push, toast, user]
  )

  return [createDocAndNavigate, { data, loading }] as const
}
