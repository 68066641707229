import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Flex,
  GridItem,
  Image,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { capitalize, DOC_DISPLAY_NAME, LoadingToastInner } from '@gamma-app/ui'
import { useCallback, useState } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { useGetDocPreviewUrlQuery } from 'modules/api'
import { Deck } from 'modules/contentful'
import { SimplifiedDeckEntry } from 'modules/example_decks/types'
import { useFeatureFlag } from 'modules/featureFlags'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'

import { getDocIdForEnv } from '../../utils'
import { TemplatesModal } from './TemplatesModal'

const buttonProps = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  transform: 'scale(.9)',
  opacity: 0,
  _groupFocus: {
    opacity: 1,
    transform: 'scale(1)',
  },
  _groupHover: {
    opacity: 1,
    transform: 'scale(1)',
  },
  _focus: {
    opacity: 1,
    shadow: 'outline',
  },
}

export const TemplateGridItem = ({
  deck,
  onPreviewClick,
  forceChannelId = null,
  onCloseEnclosingModal,
}: {
  deck: SimplifiedDeckEntry
  onPreviewClick?: (docIdToUse?: SimplifiedDeckEntry['docId']) => void
  forceChannelId?: string | null
  onCloseEnclosingModal?: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'template-browser-modal',
  })
  const analytics = useAnalytics()

  const docIdToUse = getDocIdForEnv(deck)

  const toast = useToast()
  const duplicateDoc = useDuplicateDoc()
  const [isCloning, setIsCloning] = useState(false)
  const { title, previewImageUrl } = deck

  const _onPreviewClick = onPreviewClick
    ? () => onPreviewClick(docIdToUse)
    : onOpen

  const onClone = useCallback(() => {
    if (!docIdToUse) {
      console.error('[TemplateGridItem] docIdToUse is required to clone')
      return
    }

    if (!onCloseEnclosingModal) {
      setIsCloning(true)
    }

    const duplicateToast = toast({
      id: `duplicate-toast-${docIdToUse}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ onClose, id }) {
        return (
          <LoadingToastInner
            title={`Copying ${DOC_DISPLAY_NAME}...`}
            isClosable={false}
            onClose={onClose}
            id={id}
          />
        )
      },
    }) as string

    duplicateDoc(docIdToUse, null, forceChannelId)
      .then(
        (result) => {
          if (!result?.data?.duplicateDoc) {
            console.error('[TemplateGridItem] handleDuplicateDoc error')
            return
          }
          const { id } = result?.data?.duplicateDoc
          if (analytics) {
            analytics?.track(SegmentEvents.EXAMPLE_DUPLICATED, {
              doc_id: docIdToUse,
              title: title,
              source: onCloseEnclosingModal
                ? 'template_modal_griditem'
                : 'template_dashboard_griditem',
            })
            trackDocCreatedEvent({
              doc_id: id,
              source: onCloseEnclosingModal
                ? 'template_modal_griditem'
                : 'template_dashboard_griditem',
              source_doc_id: docIdToUse,
            })
          }
          console.debug(
            `[TemplateGridItem] ${capitalize(
              DOC_DISPLAY_NAME
            )} duplicated. New doc id (${id}). Navigating to Editor`
          )
          toast.update(duplicateToast, {
            status: 'success',
            description: 'Success!',
            duration: 5000,
          })
          window.open(`/docs/${id}`, '_blank')
        },
        (e) => {
          console.error('[TemplateGridItem] Error duplicating doc:', e.message)
          toast.update(duplicateToast, {
            title: 'Error duplicating doc',
            description: e.message,
            status: 'error',
            duration: null,
            isClosable: true,
          })
          return
        }
      )
      .catch((e) => {
        console.error('[TemplateGridItem] Error duplicating doc:', e.message)
        toast.update(duplicateToast, {
          title: 'Error duplicating doc',
          description: e.message,
          status: 'error',
          duration: null,
          isClosable: true,
        })
      })
      .finally(() => {
        if (onCloseEnclosingModal) {
          onCloseEnclosingModal()
        } else {
          setTimeout(() => {
            setIsCloning(false)
          }, 3000)
        }
      })
  }, [
    analytics,
    docIdToUse,
    duplicateDoc,
    forceChannelId,
    onCloseEnclosingModal,
    title,
    toast,
  ])

  if (!docIdToUse) {
    console.error(['[TemplateGridItem] docIdToUse is not defined', deck])
    return <></>
  }

  return (
    <GridItem role="group" position="relative">
      <Stack>
        <Box
          tabIndex={0}
          role="group"
          borderRadius="5px"
          shadow="md"
          _focus={{
            // outline: 'none',
            shadow: 'outline',
          }}
          _focusWithin={{
            shadow: 'outline',
          }}
          _hover={{
            shadow: 'lg',
          }}
          position="relative"
          overflow="hidden"
          transition="box-shadow .2s ease"
          color="white"
          onClick={(e) => {
            if (
              (e.target as HTMLElement)?.closest(
                '[data-target-name="use-template-button"]'
              ) ||
              (e.target as HTMLElement)?.closest(
                '[data-target-name="preview-button"]'
              )
            ) {
              return
            }
            _onPreviewClick()
          }}
          cursor="pointer"
        >
          <AspectRatio ratio={40 / 21} bgColor="gray.100">
            <Image
              src={previewImageUrl}
              fallbackSrc={placeholderBackground.src}
              fit="cover"
            />
          </AspectRatio>

          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            inset={0}
            zIndex={2}
          >
            <Stack
              direction="column"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                {...buttonProps}
                size="sm"
                data-target-name="use-template-button"
                variant="solid"
                onClick={onClone}
                isDisabled={isCloning}
              >
                Use template
              </Button>
              <Button
                data-target-name="preview-button"
                {...buttonProps}
                onClick={_onPreviewClick}
                w="fit-content"
                size="xs"
                variant="plain"
                textDecoration="none !important"
              >
                Preview
              </Button>
            </Stack>
          </Flex>
          {/* {isNew && (
            <Badge position="absolute" bottom={5} right={5} colorScheme="green">
              New
            </Badge>
          )} */}
          <Box
            position="absolute"
            inset={0}
            bg="blackAlpha.300"
            zIndex={1}
            transitionProperty="common"
            transitionDuration="normal"
            opacity={0}
            _groupFocus={{
              opacity: 1,
            }}
            _groupHover={{
              opacity: 1,
            }}
          />
        </Box>
        <Text fontSize="md">{title}</Text>
      </Stack>
      <TemplatesModal
        forceDocId={docIdToUse as string}
        isOpen={isOpen}
        onClose={onClose}
      />
    </GridItem>
  )
}
